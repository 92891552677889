import React, { Fragment } from 'react';
import { compose } from 'recompose';
import { Link } from "gatsby"
import Helmet from "react-helmet";
import SEO from "../components/seo"
import Navlinks from '../components/nav';
import Icon from '@material-ui/core/Icon';
import Layout from '../components/layout';
import {
  withAuthorization,
  withEmailVerification,
} from '../components/Session';
import { UserList } from '../components/Users';
import * as ROLES from '../constants/roles';

import Admin_Messages from '../components/Admin_Messages';

const AdminPageBase = () => (
  <Fragment>
      <SEO title="Compass Minerals SaltSmart" />
      <Helmet defer={true}>
        <body className="adminPage" />
      </Helmet>
      <header className="mainHead flexContainer faCenter fjCenter notifactionsHead">      
        <div className="fCol12 menuCtrl">
          <Link className="headerTitle" to={`/#`}>
            <span className="arrowbk"><Icon>arrow_back</Icon></span> Admin
          </Link>              
          <Navlinks />
        </div>
      </header>
      <h1>Admin</h1>
      <p>The Admin Page is accessible by every signed in admin user.</p>
      
      <UserList />
      <hr />
      <Admin_Messages />
  </Fragment>
);

const condition = authUser =>
  authUser && !!authUser.roles[ROLES.ADMIN];

const AdminPage = compose(
  withEmailVerification,
  withAuthorization(condition),
)(AdminPageBase);

export default () => (
  <Layout>
    <AdminPage />
  </Layout>
);