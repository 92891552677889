import React, { Component } from 'react';
import { css } from "@emotion/core";
import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
import MessageList from './MessageList';
import { Router, Route, IndexRoute, Link, IndexLink, browserHistory } from 'react-router';

class Messages extends Component {
  _initFirebase = false;

  constructor(props) {
    super(props);

    this.state = {
      text: '',
      loading: false,
      messages: [],
      limit: 50,
      sortByRead: true
    };
  }

  firebaseInit = () => {
    if (this.props.firebase && !this._initFirebase) {
      this._initFirebase = true;

      this.onListenForMessages();
    }
  };

  componentDidMount() {
    this.firebaseInit();
  }

  componentDidUpdate() {
    this.firebaseInit();
  }

  onListenForMessages = () => {
    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase
      .messages()
      .orderBy('createdAt', 'asc')
      //.orderBy('mrkREAD', 'desc')
      .limit(this.state.limit)
      .onSnapshot(snapshot => {
        if (snapshot.size) {
          let messages = [];
          snapshot.forEach(doc =>
            messages.push({ ...doc.data(), uid: doc.id }),
          );

        //if (collectionObject) {
          //const PortfoliosList = Object.keys(collectionObject).map(key => ({
            //...collectionObject[key],
            //uid: key,
          //}));

          this.setState({
            //collections: PortfoliosList,
            messages: messages.reverse(),
            loading: false,
          });
        } else {
          this.setState({ messages: null, loading: false });
        }
      });
  }; 

  componentWillUnmount() {
    this.unsubscribe();
  }

  onChangeText = event => {
    this.setState({ text: event.target.value });
  };
  onChangeUsers = event => {
    this.setState({ allusers: event.target.value });
  };
  onChangeTitle = event => {
    this.setState({ msgTitle: event.target.value });
  };
  
  

  onCreateMessage = (event, authUser) => {
    this.props.firebase.messages().add({
      text: this.state.text,
      msgTitle: this.state.msgTitle,
      //userId: authUser.uid,
      userId: this.state.allusers,
      mrkREAD: false,
      //userName: authUser.username,
      createdAt: this.props.firebase.fieldValue.serverTimestamp(),
    });
    this.setState({ text: '', msgTitle: '', userId:'' });
    event.preventDefault();
  };

  onEditMessage = (message, text) => {
    const { uid, ...messageSnapshot } = message;

    //this.props.firebase.collection(collection.uid).set({
    this.props.firebase.collection(message.uid).update({
      ...messageSnapshot,
      text,
      editedAt: this.props.firebase.fieldValue.serverTimestamp(),
    });
  };

  onRemoveMessage = uid => {
    this.props.firebase.message(uid).delete();
  };

  onNextPage = () => {
    this.setState(
      state => ({ limit: state.limit + 5 }),
      this.onListenForMessages,
    );
  };

  onChangeArchiveTrue = (uid,e) => {
    this.props.firebase.message(uid).update({
      mrkREAD: true
    });
  };


  render() {
    const { text, messages,  loading, allusers, msgTitle, sortByRead } = this.state;


    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <section id="messages-content" className="flexContainer active secondaryTab" css={css`padding-top: calc(3.85 * 18px) !important;`}>
           
            <div id="messagesListContainer" className="fCol12 activeP">

            <div id="messageHeader" className="fCol12 pActive">
              <div className="flexContainer fCol12">
                <div className="portfolioInfo">
                  <h1 id="activePortTitle">NOTIFICATIONS</h1>
                </div>
              </div>
            </div>

              <form
                onSubmit={event =>
                  this.onCreateMessage(event, authUser)
                }
              >
                <input 
                  type="text"
                  value={allusers}
                  placeholder="User IDs"
                  onChange={this.onChangeUsers}
                />
                <input 
                  type="text"
                  value={msgTitle}
                  placeholder="Message Title"
                  onChange={this.onChangeTitle}
                />
                <input
                  type="textarea"
                  rows="10" cols="30"
                  value={text}
                  placeholder="Message Body"
                  onChange={this.onChangeText}
                />
                <button type="submit">Send</button>
              </form>
  
            </div>
          </section>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

export default withFirebase(Messages);